body,
html {
  margin: 0;
  /* height: 100%; */
  padding: 0;
  box-sizing: border-box;
  font-family: "D-DIN", "sans-serif";
  color: #151515;
  color-scheme: light;
  background-color: #fff;
}

#root,
#container {
  width: 100lvw;
  height: 100lvh;
  padding: 0;
  margin: 0;
}

.zizo-root {
  width: 100%;
  height: 100%;
}

.zizo-inst {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  width: 100%;
  height: 100%;
}

#zizo-btn {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 100%;
  /* padding-top: 10px; */
  padding-top: 3%;
}

#zizo-Btn-Gif {
  width: 17%;
}

#zizo-camera-container {
  display: flex;
  position: absolute;
  bottom: 0%;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#zizo-camera-first-container {
  display: flex;
  height: 80%;
}

#zizo-camera-secound-container {
  display: flex;
  height: 20%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.camera-box-inst-flex {
  width: auto;
  height: 8dvh;
  padding: 0% 2%;
  border-radius: 41px;
  background: #FFFFFFE5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#videoWindow-zizo {
  position: fixed;
  object-fit: cover;
  width: 100dvw;
  height: 100dvh;
}

#inst-inside-title-flex {
  font-size: 20px;
  font-weight: bold;
}

.svgFar {
  height: 100%;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 650px) and (orientation: landscape) {

  #gradiant_text-flex {
    font-size: 90%;
  }
}

/* @media only screen and (min-device-width: 375px) and (max-device-width: 700px) and (orientation: landscape) {

  #svgFar {
    height: 270px;
  }
} */


#zizo-recording-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 120%;
}

#zizo_recording_btn-flex {
  display: none;
  width: 8dvw;
  height: 16dvh;
  border: 1px solid #FFFFFF;
  justify-content: center;
  align-items: center;
  background: #2C67FF;
  z-index: 2;
  border-radius: 50%;
}

#recordingText-flex {
  color: #FFFFFF;
}

#zizo-arrow-guidance {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 84%;
}

#zizo-arrow {
  display: none;
  justify-content: space-between;
  width: 78%;
}

#zizo-guidance {
  display: none;
  width: 78%;
}

.zizo_arrow_left-flex {
  display: none;
  width: 150px;
  height: 60px;
  transform: rotate(-25deg);
}

.zizo_arrow_right-flex {
  display: none;
  width: 150px;
  height: 60px;
  transform: rotate(-150deg);
}

#zizo_gradiant-flex {
  display: none;
  width: 100%;
  height: 10dvh;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 0% 2%
}

#ios-canvas-zizo {
  position: fixed;
  object-fit: cover;
  width: 100dvw;
  height: 100dvh;
}

#gradiant_text-flex {
  font-size: 110%;
  font-weight: bold;
}

#zizo-btns {
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 25%;
}

#zizo_btn_no-flex {
  display: none;
  width: 7dvw;
  height: 14dvh;
  border-radius: 50%;
  background: #2C67FF;
  border: 1px solid #FFFFFF;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

#zizo_btn_yes-flex {
  display: none;
  width: 7dvw;
  height: 14dvh;
  border-radius: 50%;
  background: #2C67FF;
  border: 1px solid #FFFFFF;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.zizo_btn_text-flex {
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
}

.inst-inside-title-flex {
  font-size: 100%;
  font-weight: bold;
}

#zezoStopHand-flex {
  display: none;
  width: 5dvw;
  height: 11dvh;
  z-index: 2;
}

#zizo-recording-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding-bottom: 3%;
}

#record_gif-flex {
  display: none;
  width: 13%
}

#recordGuidText-flex {
  display: none;
  font-size: 140%;
  font-weight: bold;
  color: #FFFFFF;
  margin: 0px;
}

#camera_red_box {
  display: block;
  height: 100dvh;
  width: 100dvw;
  opacity: 0.7;
  object-fit: cover;
}

#navbar {
  height: 42px;
  width: 100%;
  padding: 8px 0;
  background-color: #151515;
}

#portNavbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 45px;
  width: 100dvw;
  padding: 8px 0;
  background-color: #151515;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
}

#nav-back {
  height: 24px;
  width: 24px;
  position: absolute;
  left: 30px;
  top: 20px;
  color: #fff;
}

#logo {
  width: 66px;
  height: 40px;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

#logoP {
  width: 66px;
  height: 40px;
  object-fit: contain;
  display: block;
  margin-right: 20px;
}

#screen1 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
}

#menuP-mixed-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 150px;
  width: 90%;
  position: absolute;
  bottom: 40px;
  align-items: center;
  padding: 0px 0px 0px 20px;
}

#screen2 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

#screen2 h1 {
  font-size: 24px;
  text-align: center;
  line-height: 150%;
  font-weight: 600;
  letter-spacing: -2%;
  font-style: normal;
  color: #212121;
  vertical-align: top;
}

.screen2-rules {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.screen2-rules div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #212121;
  padding: 5px;
  border-radius: 50%;
  margin-right: 15px;
  font-weight: 800;
  text-align: center;
  width: 18px;
  height: 18px;
  -ms-flex-align: start;
  font-size: 16px;
  flex-basis: 20px;
}

.screen2-rules p {
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: -2%;
  text-align: left;
  font-weight: 600;
  flex-basis: 90%;
  margin-top: 20px;
}

#screen6 {
  display: flex;
  flex-direction: column;
  padding: 10px 40px;
}

.process {
  height: 100dvh;
  display: flex;
  padding: 0% 2%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.process h2 {
  margin: 15px 0px 6px 0px;
}

.process-main {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: auto;
}

.left-btn,
.right-btn {
  width: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-btn img,
.right-btn img {
  height: 40px;
  width: 40px;
  border: none;
}

.process-images {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 5em;
  overflow-x: scroll;
}

.process-steps {
  display: flex;
  width: 80vw;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 3.9em;
}

.process-images div {
  text-align: center;
  height: 130px;
  justify-content: center;
  align-items: center;
}

.process-steps h4 {
  width: 150%;
  margin: 0px;
  text-align: center;
}

.process-done {
  border: 2px solid #13da0e !important;
}

.process-start {
  border: 2px solid #006cc9 !important;
}

.greenCircleTick {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: lightgreen;
  z-index: 4;
}

.process-vin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  margin: auto;
  height: 110px;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.process-odo,
.process-360,
.process-zoom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  margin: auto;
  height: 110px;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  overflow: hidden;
}

.process-360 img,
.process-vin img,
.process-odo img,
.process-zoom img {
  object-fit: cover;
  background-repeat: no-repeat;
  width: 100%;
  /* height: auto; */
  height: 100%;
  padding: 3px;
}

.process-zoom img {
  opacity: 0.4;
}

.process-btn {
  color: #fff;
  background-color: #006cca;
  border: 2px solid rgba(0, 0, 0, 0);
  height: 50px;
  line-height: 48px;
  width: 200px;
  margin-right: 20px;
}

.email_screen {
  padding: 2% 4%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.screen {
  padding: 2% 4%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.title h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.header1 {
  font-size: 24px;
}

.email_btn {
  /* position: absolute; */
  /* left: 0px;
  bottom: 10px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header2 {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.31;
  text-align: center;
}

.header3 {
  margin: 0 14px;
  font-size: 16px;
  line-height: 1.31;
}

.ftext {
  font-family: "D-DIN";
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: #151515;
}

.fimg {
  width: 100px;
  height: 100px;
  margin: 18px 0 18px 18px;
  object-fit: contain;
}

.flex {
  display: flex;
  flex-direction: row;
}

.fl-3 {
  flex-grow: 3;
}

form {
  display: block;
}

label {
  display: block;
  margin: 10px 0;
  font-family: "D-DIN";
  font-size: 12px;
  font-weight: bold;
  line-height: 0.83;
  color: #006cca;
}

input[type="email"],
input[type="text"] {
  margin: 0 0 10px 0;
  padding: 9px 14px 9px 14px;
  font-family: "D-DIN";
  font-size: 20px;
  line-height: 0.88;
  color: #1d1d26;
  width: 93%;
  height: 36px;
  outline: 0;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
}

input[type="email"]:focus,
input[type="text"]:focus {
  border-color: #006cca;
}

a {
  color: #006cca;
  text-decoration: none;
}

.btn {
  margin: 6px 0;
  display: block;
  text-shadow: 0 10px 40px #40d883;
  font-family: "D-DIN";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  letter-spacing: 1px;
}

.btn2 {
  display: block;
  text-shadow: 0 10px 40px #40d883;
  font-family: "D-DIN";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  letter-spacing: 1px;
}

.btn-blue {
  color: #fff;
  border: 2px solid rgba(0, 0, 0, 0);
  height: 50px;
  line-height: 48px;
  width: 250px;
}

.btn-blue-translucent {
  color: #fff;
  background-color: rgba(2, 127, 236, 0.3);
  border: 2px solid #006cca;
  height: 48px;
  line-height: 48px;
}

.btn-white {
  background-color: #fff;
  color: #006cca;
  height: 46px;
  line-height: 46px;
  border: solid 1px #006cca;
}

.btn-white:hover {
  background-color: #eee;
}

.btn-disabled {
  /* background-color: #bcbcbc !important; */
  color: #fff !important;
  /* border-color: #eee !important; */
}

.btn-normal {
  width: 160px;
}

.btn-wide {
  width: 90%;
  padding: 5px 14px 5px 14px;
}

#shadow {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
}

.instructions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  height: auto;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  padding: 10px;
}

.instructions>h3 {
  margin: 20px;
}

.instructions>ul {
  padding: 0 0 0 34px;
}

.instructions>ul>li {
  font-family: "D-DIN";
  font-size: 14px;
  line-height: 1.64;
  margin: 10px 0;
  padding-right: 20px;
}

#android-instructions,
#ios-instructions,
#android2,
#ios2,
#android4,
#post-upload-ios,
#post-upload-android {
  display: none;
}

#android4-notice {
  text-align: center;
  font-family: "D-DIN";
  font-size: 18px;
  font-weight: bold;
}

#ios-video-input {
  visibility: hidden;
}

#ios-step2 {
  width: 168px;
  object-fit: contain;
}

#ios-video-select {
  margin: 42px 0;
  height: 150px;
  width: auto;
  border-radius: 8px;
  border: dashed 1px #006cca;
  background-color: rgba(0, 108, 202, 0.1);
  font-family: "D-DIN";
  font-size: 14px;
  font-weight: bold;
  line-height: 150px;
  text-align: center;
  color: #006cca;
}

#ios-video-selected {
  display: none;
  width: 160px;
  height: 90px;
  margin: auto;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  text-align: center;
}

#ios-video-remove {
  color: #ff4b5a;
  position: relative;
  top: -10px;
  right: -146px;
  width: 22px;
  height: 22px;
}

#ios-video-remove>.fa-times-circle {
  background-color: #fff;
  border-radius: 50px;
}

.text-blue {
  margin: 11px;
  display: inline-block;
  font-family: "D-DIN";
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #006cca;
}

#rotate-image-1,
#rotate-image-2,
#loading-spinner {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
}

#rotate-image-1 {
  margin-top: 200px;
}

#rotate-image-2 {
  margin-top: 50px;
}

#loading-spinner {
  margin-top: 100px;
}

.note {
  font-family: "D-DIN";
  font-size: 24px;
  font-weight: bold;
  line-height: 1.67;
  text-align: center;
}

.note-light {
  font-size: 14px;
  color: #bcbcbc;
}

#screen3 {
  width: 100%;
  height: 100%;
}

#videoWindow,
#ios-canvas {
  position: fixed;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#ios-canvas2 {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  left: 0;
  top: 0;
}

#playbackWindow {
  width: 100%;
  height: auto;
  object-fit: cover;
  background-color: #000;
}

#video-instructions {
  font-family: "D-DIN";
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #aaa;
}

#video-instructions {
  position: absolute;
  top: 10px;
  width: 100%;
}

#video-recording-text {
  left: 50px;
  position: absolute;
  font-family: "D-DIN";
  text-align: center;
  color: black;
  background-color: rgba(255, 255, 255, 0.45);
  padding: 10px;
  bottom: 15px;
  margin: auto;
  width: 60%;
  margin-left: 6%;
  border-radius: 30px;
}

#btn-android-start {
  display: none;
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: #e84141;
  border: 7px solid #fff;
  border-radius: 46px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

#btn-android-start1 {
  position: fixed;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: #929292;
  border: 7px solid #fff;
  border-radius: 46px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
}

#btn-android-start:hover {
  background-color: #ca2929;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
}

#btn-android-stop:before {
  content: "";
  position: fixed;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  background-color: #eee;
  border-radius: 3px;
}

#btn-android-stop {
  display: none;
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 65px;
  height: 65px;
  background-color: #e84141;
  border-radius: 50px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.allgreenstop1 {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 65%;
  left: 5%;
  top: 12%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  color: black;
}

.allgreenstop_bodyDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  height: 85%;
}

.allgreenstop,
.vinModal {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  left: 5%;
  top: 5%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  justify-content: space-around;
  align-items: center;
  color: black;
}

.allgreenstop2_innerDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.allgreenstop2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 70%;
  left: 5%;
  top: 10%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  align-items: center;
}

@media only screen and (min-device-width: 355px) and (max-device-width: 510px) and (orientation: landscape) {

  #ackoBtn {
    font-size: 14px;
  }
}

.allgreenstop2_bodyDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55%;
  height: 80%;
  padding-right: 2%;
  padding-left: 2%;
}

.allgreenstop3 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 68%;
  left: 10%;
  top: 12%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  align-items: center;
}

.allgreenstop3_title {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.allgreenstop3_body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.allgreenstop3_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .allgreenstop2 {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 90%;
  left: 5%;
  top: 5%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  align-items: center;
} */

/* #btn-android-stop:hover {
  background-color: #eee;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
} */

#processing-loader {
  height: 50px;
  width: 50px;
  margin: 20px auto;
}

/*-------------------------------------------------------------------------------------------------*/

#model-loader {
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: white;
}

.progress {
  width: 50px;
  height: 50px;
  font-size: 15px;
  color: black;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: white;
  text-align: center;
  line-height: 50px;
}

.progress .title {
  position: relative;
  z-index: 100;
}

.progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.progress .left,
.progress .right {
  width: 40%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  border: 5px solid black;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
}

.progress .left {
  animation: load1 1s linear forwards;
}

.progress:nth-of-type(2) .right,
.progress:nth-of-type(3) .right {
  animation: load2 0.5s linear forwards 1s;
}

.progress:last-of-type .right,
.progress:first-of-type .right {
  animation: load3 0.8s linear forwards 1s;
}

@keyframes load1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes load2 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(270deg);
  }
}

@keyframes load3 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(315deg);
  }
}

/*-------------------------------------------------------------------------------------------------*/

#btn-car-container {
  position: absolute;
  width: 115px;
  height: 100%;
  right: 0%;
}

#display-card {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 60%;
  left: 5%;
  top: 15%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  justify-content: space-around;
  align-items: center;
  color: black;
}

#stop-display-card {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 60%;
  left: 5%;
  top: 15%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  justify-content: space-around;
  align-items: center;
  color: black;
}

.videoWarning {
  position: "absolute";
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#acko-review-card {
  position: absolute;
  display: flex;
  width: 80%;
  height: 65%;
  left: 5%;
  top: 10%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  gap: 10%;
  align-items: center;
  color: black;
}


#review-card {
  position: absolute;
  flex-direction: column;
  display: flex;
  width: 75%;
  height: 75%;
  left: 5%;
  top: 10%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  justify-content: space-around;
  align-items: center;
  color: black;
}

#review-card h4 {}

#review-restricted-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;
  left: 5%;
  top: 5%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  justify-content: space-around;
  align-items: center;
  color: black;
}

#review-restricted-card h4 {
  text-align: center;
}

#display-card h3 {
  text-align: center;
}

#display-card img {
  /* height: 130px; */
}

@media screen and (max-width: 620px) {
  #display-card img {
    /* height: 170px; */
  }
}

@media screen and (max-height: 350px) {
  #display-card img {
    /* height: 150px; */
  }
}

#review-card h3 {}

#review-restricted-card h3 {
  text-align: center;
}

.review-images {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  height: auto;
}

.review-images .image {
  display: flex;
  flex-basis: 35%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.review-images .image h4 {
  text-align: center;
  margin-top: 45%;
}

.review-images div {
  flex-basis: 65%;
  max-width: 65%;
}

.review-btns {
  display: flex;
}

@media screen and (max-width: 675px) {
  .review-btns .btn-blue {
    width: 165px;
  }

  .review-images .image h4 {
    font-size: 13px;
  }
}

@media screen and (max-height: 391px) {
  .review-images .image img {
    width: 80px;
  }

  .reels-modal.active {
    margin-top: 10px;
  }
}

@media screen and (max-height: 340px) {
  .review-btns .btn-blue {
    margin-top: 10px;
  }
}

#window-arc {
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 40px;
  height: 70px;
  width: 70px;
  background-color: white;
}

#window-arc2 {
  position: absolute;
  right: 30px;
  bottom: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 40px;
  height: 79px;
  width: 79px;
}

#window-arc2 img.car {
  height: 56px;
  position: absolute;
  z-index: 2;
}

#window-arc img.car {
  height: 43px;
  position: absolute;
  z-index: 2;
}

#window-arc1 {
  position: absolute;
  right: 12px;
  bottom: 10px;
}

#window-arc1 img.car {
  height: 55px;
  width: auto;
  position: absolute;
  top: 24px;
  left: 36px;
  z-index: 2;
}

#screen3-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  font-family: "D-DIN";
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

#screen3-modal .modal-column {
  flex: 50%;
  padding: 20px;
}

.full-center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-btn-flex {
  display: flex;
}

.modal-btn-flex div {
  flex: 50%;
  margin: 10px;
}

.reel-container {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 7px 35px;
  z-index: 22;
}

.reels {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: scroll;
  width: 65%;
}

.reels img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 4px;
  flex-shrink: 0;
}

.btn-blue-small {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 2px;
  font-weight: bold;
  text-align: center;
  transition: opacity 0.5s ease;
  cursor: pointer;
  background-color: #006cca;
  color: #fff;
  height: 50px;
  flex-shrink: 0;
  min-width: 70px;
}

.reels-modal {
  display: none;
  background-color: rgba(0, 0, 0, 1);
  z-index: 20;
}

.reels-modal.active {
  display: block;
  /* margin-left: 20%; */
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  margin: 0px;
  padding: 0px;
  height: auto;
}

.reels-modal.active img {
  width: 100vw;
  height: 99.9vh;
  margin: 0px;
  padding: 0px;
}

.reels-modal img {
  object-fit: cover;
}

#vin-image {
  display: none;
  position: relative;
}

#vin-image img {
  display: flex;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  z-index: 3;
}

.vin-btn-retake {
  position: absolute;
  color: #fff;
  font-size: 36px;
  padding: 7px 0px 0px 10px;
  border: 2px solid white;
  height: 40px;
  width: 35px;
  left: 15%;
  bottom: 30%;
  display: inline-block;
}

.vin-btn-next {
  position: absolute;
  color: #fff;
  padding: 7px 0px 0px 7px;
  border: 2px solid white;
  height: 40px;
  width: 40px;
  bottom: 30%;
  right: 15%;
  display: inline-block;
}

#port-btn-vin-start {
  display: none;
  position: fixed;
  left: 40%;
  bottom: 10px;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  background-color: #929292;
  border: 7px solid #fff;
  border-radius: 46px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 2;
}

#btn-vin-start {
  display: none;
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: #929292;
  border: 7px solid #fff;
  border-radius: 46px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.camera-reverse {
  display: block;
  position: fixed;
  right: 30px;
  bottom: 1%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: #929292;
  border: 7px solid #fff;
  border-radius: 46px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.submitted img {
  width: 40px;
  height: 40px;
  border: 2px solid black;
  border-radius: 47%;
  padding: 15px;
}

.progress-final {
  width: 50px;
  height: 50px;
  font-size: 15px;
  color: black;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: white;
  text-align: center;
  line-height: 50px;
}

.progress-final .title {
  position: relative;
  z-index: 100;
}

.progress-final .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.progress-final .left,
.progress-final .right {
  width: 40%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  border: 5px solid black;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
}

.progress-final .left {
  animation: load1 1s linear forwards;
}

.progress-final:nth-of-type(2) .right,
.progress-final:nth-of-type(3) .right {
  animation: load2 0.5s linear forwards 1s;
}

.progress-final:last-of-type .right,
.progress-final:first-of-type .right {
  animation: load3 0.8s linear forwards 1s;
}

@keyframes load1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes load2 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(270deg);
  }
}

@keyframes load3 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(315deg);
  }
}

#final-screen {
  justify-content: center;
  align-items: center;
}

#rejection-screen {
  justify-content: center;
}

#vin_odo-camera-guidance {
  display: none;
  align-items: center;
  position: absolute;
  flex-direction: column;
  width: 75%;
  height: 75vh;
  left: 8%;
  top: 12%;
  z-index: 3;
  justify-content: space-between;
  background: rgba(24, 24, 24, 0.84);
  backdrop-filter: blur(7.5px);
  border-radius: 19px;
  color: white;
}

#warn-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 90%;
  left: 10%;
  top: 5%;
  background-color: #ffffff;
  z-index: 3;
  border-radius: 30px;
  align-items: center;
  padding: 15px;
}

.submitted svg {
  display: none;
}

.submitted .progressbar-text {
  display: none;
}

.result-wait {
  display: flex;
  flex-direction: column;
  color: #202020;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.nav-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.nav1 {
  display: flex;
  color: #ffffff;
  justify-content: space-around;
  align-items: center;
  background-color: #202020;
  font-weight: lighter;
  padding: 6px;
  border-bottom: solid 1px #ffffff;
}

.nav-btn {
  background-color: #006cc9;
  padding: 8px;
  border-radius: 7px;
}

.nav2 {
  display: flex;
  color: #202020;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;
  font-weight: lighter;
  font-size: 12px;
  padding: 6px;
}

.editor-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editor-modal {
  display: flex;
  background-color: rgb(249, 250, 250);
  width: 90%;
  max-height: 20%;
  margin-top: 30px;
  padding: 10px;
  border-radius: 20px;
  color: #202020;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.15);
}

.editor-details {
  display: flex;
  flex-direction: column;
  min-width: 60%;
  gap: 1em;
}

.editor-details div span {
  display: block;
  margin-top: 6px;
  font-size: 11px;
}

.editor-img {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
  padding: 5px;
}

.editor-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2em;
  color: #006cca;
}

.show-image {
  min-height: 55%;
  max-width: 60%;
  border-radius: 20px;
}

.edit-options {
  display: flex;
  flex-direction: column;
  gap: 2em;
  height: 70%;
  padding-left: 25px;
  width: 95vw;
}

.edit-options h2 {
  text-align: center;
}

.edit-options div label {
  font-size: 20px;
  margin-bottom: 15px;
  color: black;
}

.edit-options div select {
  width: 90%;
  height: 75%;
  border: solid #a1a1a1 1px;
  border-radius: 10px;
  padding: 7px;
}

.edit-btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  gap: 2em;
}

.save-btn {
  background-color: #006cca;
  color: white;
  border-radius: 10px;
  padding: 10px;
  width: 35%;
  text-align: center;
}

.cancel-btn {
  color: black;
  border-radius: 10px;
  padding: 10px;
  width: 35%;
  text-align: center;
  border: solid black 1px;
}

.submit-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7%;
  background-color: #202020;
  position: fixed;
  bottom: 0;
  left: 0;
}

.submit-nav-btn,
.fetch-result-btn {
  background-color: #006cc9;
  border-radius: 10px;
  padding: 10px;
  color: white;
  width: 50%;
  text-align: center;
}

@media only screen and (max-height: 650px) {
  .edit-options {
    width: 100vw;
    height: 100vh;
    gap: 1.2em;
  }

  .edit-options h2 {
    font-size: medium;
  }

  .edit-options div label {
    font-size: 15px;
    margin-bottom: 10px;
    color: black;
  }

  .submit-nav {
    height: 15%;
  }
}

.post-edit-submit {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  margin-top: 12%;
  text-align: center;
}

.post-edit-submit img {
  width: 20%;
}

.final-gif {
  width: 20%;
}

@media screen and (max-width: 800px) {
  .post-edit-submit img {
    width: 50%;
  }
}

.instructions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  height: auto;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
}

.email-skip {
  color: #006cca;
  font-weight: normal;
  text-decoration: underline;
}

.nav2-container {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.nav2-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #202020;
  font-size: 12px;
}

.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.post-sub {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.post-sub-div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.zoom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zoom-container h3 {
  margin: 5px;
}

.zoom-ALigment {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.captured {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-right: 10px;
  width: 22px;
  height: 22px;
  background-color: #28dc76;
  color: white;
}

.pending {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
}

.timg {
  max-height: 200px;
  max-width: 250px;
  border-radius: 50px;
}

.timg img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 11px;
}

.rimg {
  max-height: 200px;
  max-width: 250px;
  border-radius: 50px;
}

.rimg img {
  height: auto;
  width: 170px;
  border-radius: 11px;
}

.close {
  display: none;
}

.zoom-gif {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: scroll;
  width: 100dvw;
  height: 100dvh;
}

.zoom-gif h1 {
  font-size: 26px;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.dmgIns {
  justify-content: space-evenly;
  height: 70vh;
  display: flex;
  flex-direction: column;
}

.inst {
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}

.inst p {
  font-weight: 100;
  font-size: 14px;
}

.inst img {
  width: 80px;
  height: 45px;
}

.inst div {
  margin-left: 12px;
}

.inst div p {
  margin: 5px 0px;
}

.inst div h3 {
  margin: 0px;
}

.btn-flex {
  display: flex;
  gap: 1em;
}

.screen18 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100dvh;
  position: relative;
}

.confirmEnd {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: #8a888845;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmEndBox {
  width: 80%;
  height: 50%;
  background-color: #fff;
  flex-direction: column;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.confirmEndBox p {
  font-size: 25px;
  font-weight: 600;
}

.confirmEndBoxBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.screen18 h1 {
  font-size: 26px;
  margin: 0px;
  text-align: center;
  margin-top: 3px;
}

.sep-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20vh;
  background-color: #F8F7FC;
}

.sep-btn-div {
  background-color: #F8F7FC;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

/* .sep-btn {
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
} */

.skip {
  margin: 24px 0;
  display: block;
  text-shadow: 0 10px 40px #40d883;
  font-family: "D-DIN";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-right: auto;
  border-radius: 4px;
  letter-spacing: 1px;
  border: none;
  font-size: 16px;
  color: #fff;
  background-color: #006cca;
  height: 46px;
  width: 180px;
}

.start {
  margin: 24px 0;
  display: block;
  text-shadow: 0 10px 40px #40d883;
  font-family: "D-DIN";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  border-radius: 4px;
  letter-spacing: 1px;
  border: none;
  font-size: 16px;
  color: #fff;
  background-color: #006cca;
  height: 46px;
  width: 180px;
}

.final-button {
  display: flex;
  flex-direction: row;
}

.final-button div {
  margin: 20px 75px;
}

.damage-btn {
  background-color: #F8F7FC;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 20vh;
}

.damage-btn-div {
  background-color: #F8F7FC;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

/* .damage-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10em;
} */

.swiper {
  height: 60vh;
}

.process-swiper {
  height: 60vh;
}

.menu-optionsP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  height: 130px;
}

.process-steps-wondle {
  display: flex;
  width: 100%;
  height: 40%;
  margin-top: 15dvh;
  flex-wrap: wrap;
  padding: 0px 5px 0px 5px;
}

.menu-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

.menu-options>* {
  flex: 1 0 auto;
}

.process-swiper h2 {
  text-align: center;
}

.menu-titles {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-info {
  color: white;
  background-color: #006cca;
  border-radius: 40px;
  width: 60px;
  font-size: 17px;
  padding: 3% 6%;
}

.menu-info strong {
  margin-left: 9%;
}

.description {
  margin-top: 5%;
  background-color: rgb(241, 240, 240);
  width: 40%;
  text-align: center;
}

.qr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.qr video {
  width: 100vw;
  height: 122vh;
}

.qr-border {
  width: 55vw;
  height: 70vh;
  border: 5px solid red;
  position: absolute;
}

.email-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2em;
}

.menu-modal-container {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: #d1d1d16e;
}

#damage-capture-modal {
  display: none;
  align-items: center;
  position: absolute;
  flex-direction: column;
  width: 90%;
  height: 20vh;
  left: 5%;
  top: 30%;
  z-index: 3;
  justify-content: space-between;
  background: white;
  backdrop-filter: blur(7.5px);
  border-radius: 19px;
}

#damage-capture-modal h3 {
  margin-top: 20px 0px 0px 0px;
  text-align: center;
  padding: 10px 10px 0px 10px;
}

#camera-guidance-modal {
  display: none;
  align-items: center;
  position: absolute;
  flex-direction: column;
  width: 90%;
  height: 40vh;
  left: 5%;
  top: 25%;
  z-index: 3;
  justify-content: space-between;
  background: rgba(24, 24, 24, 0.84);
  backdrop-filter: blur(7.5px);
  border-radius: 19px;
  color: white;
}

#camera-guidance-modal h3 {
  margin-top: 20px 0px 0px 0px;
  text-align: center;
  padding: 10px 10px 0px 10px;
}

.menu-modal-port {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding-left: 10%;
  padding-right: 10%;
  width: 70vw;
  height: 25vh;
  border-radius: 40px;
  z-index: 4;
  text-align: center;
}

.menu-modal {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  margin-top: 40px;
  padding-top: 2%;
  padding-left: 10%;
  padding-right: 10%;
  flex: 0 0 55%;
  width: 60vw;
  border-radius: 40px;
  z-index: 4;
}

.modal-btn-container-multipleImage {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 2em;
  width: 100%;
}

.modal-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  width: 100%;
  padding-right: 2%;
}

/* .modal-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
} */

.back-btn {
  color: white;
  border-radius: 50%;
  border: 2px solid white;
  padding: 10px;
  position: absolute;
  top: 30px;
  right: 35px;
}

.vinIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 30px;
  right: 35px;
  border-radius: 25px;
}

.vinIndicator p {
  padding: 0px;
  margin: 0px;
  color: white;
  font-size: larger;
  font-weight: 600;
}

.menu-back-btn {
  color: black;
  border-radius: 10px;
  padding: 12px;
  position: absolute;
  top: 30px;
  right: 30px;
}

.car-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10px;
  height: 80%;
  padding-right: 15px;
}

.card-info div {
  display: flex;
  align-items: center;
  gap: 1em;
}

.card-note {
  width: 97%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.car-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 30px;
}

.pre-start-card div {
  flex: 0 1 auto;
}

.pre-start-card h5 {
  flex: 0 0 80px;
}

.pre-start-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.car-info div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.car-red {
  background-color: #de524c;
  border-radius: 8px;
  width: 15px;
  height: 30px;
}

.car-green {
  background-color: #6cd21a;
  border-radius: 8px;
  width: 15px;
  height: 30px;
}

/* .car-red {
  background-color: #de524c;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.car-green {
  background-color: #6cd21a;
  border-radius: 50%;
  width: 15px;
  height: 15px;
} */

/* ****************************************************Zero app**************************************************** */

.zero-container {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.zero-container-div {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}

.zero-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zero-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
  display: flex;
}

.zero-div-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;
  color: white;
  height: 40px;
  width: 120px;
  background-color: #006cca;
  border-radius: 5px;
  cursor: pointer;
}

.zero-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
  display: flex;
  height: 100px;
  width: 100px;
  border: 2px solid;
  border-radius: 5px;
}

.zero-item img {
  padding: 0.5rem;
  object-fit: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}

.zero-item:hover {
  cursor: pointer;
}

.zero-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.zero-canvas {
  display: none;
}

.zero-preview {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2;
}

.zero-previewImg {
  padding: 0px;
  margin: 0px;
  z-index: 3;
  width: 100vw;
  height: 100vh;
}

.zero-button-click {
  display: block;
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: #929292;
  border: 7px solid #fff;
  border-radius: 46px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.zero-camera {
  width: 20px;
  height: 20px;
  /* padding: 5px; */
}

.zero-tick {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  width: 6rem;
  height: 1.5rem;
  z-index: 4;
  position: absolute;
  bottom: 50px;
  left: 100px;
  cursor: pointer;
}

.zero-retake {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  height: 1.5rem;
  width: 6rem;
  z-index: 4;
  position: absolute;
  bottom: 50px;
  right: 100px;
  cursor: pointer;
}

.zero-previewBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* **************************************************************************************************************** */

.qr-popup {
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  top: 15%;
  left: 12%;
  margin-top: 40px;
  padding-top: 2%;
  padding-left: 10%;
  padding-right: 10%;
  width: 60vw;
  border-radius: 40px;
}

.back-div {
  background-color: #F8F7FC;
  display: flex;
  /* width: 100%; */
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}

.back-btn-div {
  background-color: #F8F7FC;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
}

/* .back-div {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.start-btn {
  margin: 24px 0;
  display: block;
  text-shadow: 0 10px 40px #40d883;
  font-family: "D-DIN";
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  letter-spacing: 1px;
  border: none;
  font-size: 16px;
  color: #fff;
  background-color: #006cca;
  height: 50px;
  width: 200px;
}

#myCanvas {
  position: absolute;
  background-color: transparent;
  left: 0;
  top: 0;
}

.warning {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.warning div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  letter-spacing: 1px;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  background-color: #212121a4;
  color: #ffffff;
  border-radius: 22px;
}

.form {
  font-family: "D-DIN";
  /* padding: 20px 32px 50px 32px; */
  width: 100%;
}

.form h1,
.form-footer {
  /* width: 80%; */
  font-family: "D-DIN";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.form h3 {
  /* width: 80%; */
  font-family: "D-DIN";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.form-btn {
  font-family: "D-DIN";
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  font-family: "D-DIN";
  color: #5a5a5a;
  font-size: 15px;
}

.label-div {
  display: flex;
  align-items: center;
}

.label-div p {
  font-family: "D-DIN";
  padding-left: 10px;
}

.label-div input[type="radio"] {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.label-div p {
  font-family: "D-DIN";
  font-size: 16px;
  font-weight: 600;
}

.form-btn button {
  color: #fff;
  font-family: "D-DIN";
  background-color: #006cca;
  border: 2px solid rgba(0, 0, 0, 0);
  height: 50px;
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
  border-radius: 5px;
  font-weight: 600;
}

.form-select {
  font-family: "D-DIN";
  border: 1px solid #dbdbdb;
  /* width: 100%; */
  width: 96.5%;
  height: 55px;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}

.div-input {
  font-family: "D-DIN";
  /* width: 100%; */
  width: 95%;
}

.div-input input {
  font-size: 16px;
}

.form-select-option {
  font-family: "D-DIN";
  width: 50px;
}

.documents {
  margin-top: 25px;
}

.documents input {
  font-family: "D-DIN";
  margin-top: 10px;
}

.radiodiv {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
}

.radiodiv p {
  font-family: "D-DIN";
  color: red;
}

.label span {
  color: red;
}

.pings {
  padding: 3px;
  position: fixed;
  top: 3px;
  right: 7px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: transparent;
}

.pingsPort {
  padding: 3px;
  position: fixed;
  top: 60px;
  right: 15px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  z-index: 2;
}

.pings p {
  font-size: 11px;
  margin: 0px;
  padding: 0px;
}

.pingBar {
  margin-right: 16px;
  height: 10px;
  width: auto;
  position: relative;
  display: flex;
  flex-direction: row;
}

.statBar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 5px;
  width: 2px;
  background-color: red;
}

.statBarTwo {
  position: absolute;
  bottom: 0px;
  left: 4px;
  height: 8px;
  width: 2.5px;
  background-color: red;
}

.statBarThree {
  position: absolute;
  bottom: 0px;
  left: 8px;
  height: 11px;
  width: 2.5px;
  background-color: red;
}

.InternetWarning {
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.InternetWarning p {
  font-weight: 600;
  color: red;
  margin: 2px;
  padding: 0px;
}

.permissionWarning {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px;
  margin-top: 2px;
  margin-bottom: 10px;
}

.permissionWarningtext {
  font-weight: 600;
  color: red;
  padding: 20px 0px 20px 10px;
  margin: 0px;
}

.clickBtn {
  display: none;
}

.notCoveredAreas {
  display: flex;
  flex-direction: column;
  flex-grow: 0.3;
  width: 90%;
}

/* .notCoveredAreas {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
} */

.inputFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imageRemovebutton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 5px;
  background-color: rgba(21, 122, 255, 1);
  height: 18px;
  width: 18px;
  border-radius: 10px;
  text-align: center;
  color: white;
  font-size: 10px;
}

.statusDisplay2 {
  margin-left: -10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.statusDisplay {
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.vin1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: white;
  margin: 0;
  border-radius: 25px;
  -webkit-transition: 0.5s;
}

.vin1 div {
  font-size: 18px;
  height: 40px;
  width: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 20px;
  font-weight: 500;
}

.activeDial {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 83px;
  width: 83px;
  background-color: white;
  border-radius: 45px;
  -webkit-transition: 1s;
}

.statusBar {
  background-color: white;
  margin: 0;
  width: 6px;
  height: 40px;
  -webkit-transition: 0.3s;
}

.vin2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: white;
  margin: 0;
  border-radius: 25px;
  -webkit-transition: 1s;
}

.vin2 div {
  font-size: 18px;
  height: 40px;
  width: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 20px;
  font-weight: 500;
}

.vinDetail {
  display: flex;
  width: 400px;
  flex-direction: column;
}

.displayVinStructure {
  margin: 4% 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.displayVinStructure2 {
  margin: 4% 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayVin {
  font-size: 18px;
  height: 40px;
  width: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 20px;
  font-weight: 500;
}

.displayVin {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepDisplay {
  display: flex;
  flex-direction: column;
}

.stepDisplay p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.camerInst {
  bottom: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  position: absolute;
}

.camerInst p {
  color: white;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 10px;
  border-radius: 6px;
}

.damageConfirmationPopup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.InstrBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: sticky;
  bottom: 10px;
  left: 0px */
}

.permissionCss {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 5%; */
}

.permissionCssdiv {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  width: 76vw;
}

.permissionCssdiv h4 {
  text-align: left;
  margin-right: 2%;
  margin: 0px;
}

.permissionCssdiv p {
  text-align: left;
  margin-right: 2%;
  margin: 0px;
  padding-top: 10px;
  padding-right: 7px;
}

.permmisionbtndiv {
  width: 16vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.permmisionbtndiv button {
  border-radius: 4px;
  padding: 8px 0;
  width: 100%;
  color: #fff;
  border: none;
  font-family: "D-DIN";
}

.timerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.timerDiv p {
  font-size: large;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
}

.videoRecoderPopup {
  display: none;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
}

.videoRecoderPopupdiv {
  width: 80vw;
  height: 80vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 10px;
}

.countingForm {
  font-size: 20px;
  /* position: absolute; */
  /* right: 15%; */
  /* top: 5%; */
  font-family: "D-DIN";
  font-weight: 600;
  margin: 0px;
}

.checkboxLevel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 10px;
}

.sampleCheckbox {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#btn-image-capture {
  display: none;
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.reel-container1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  width: 100vw;
  bottom: 0;
  left: 0;
  height: 64px;
  display: flex;
  padding: 7px 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.reels1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80vw;
}

.reels1 img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 4px;
  flex-shrink: 0;
}

.reels-modal1 {
  display: none;
}

.reels-modal.active1 {
  display: flex;
  z-index: 1;
}

.close1 {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 40px;
  font-weight: 600;
  color: white;
}

.reels-modal1 img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.customPopup {
  display: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.customPopupdiv {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  flex-direction: column;
}

.timeout {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.timeout h1 {
  margin: 12px 0px;
}

.timeout p {
  margin: 10px 0px;
  font-weight: 600;
}

.redLineVINcontainerView {
  display: none;
  z-index: 3;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: 100vh;
  background-color: transparent;
  gap: 15px;
  flex-direction: column;
}

.vinVideoPopup,
.VINResult,
.redLineVINcontainer {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}

.vinVideoPopupcontainer,
.VINResultContainer {
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background-color: white;
  width: 70vw;
  height: 60vh;
}

.redLineVIN {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  border: 4px solid white;
  background-color: transparent;
}

.VINIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 50px;
  right: 20px;
  top: 30px;
  position: absolute;
  height: 20px;
  color: white;
  width: 20px;
  padding: 20px;
}

.VINIndicator1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 50px;
  height: 20px;
  color: white;
  width: 20px;
  padding: 10px;
  font-size: 14px;
}

.VINResultContainerbtn,
.VINResultContainerTxt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.imageFeedBackCompletePopup {
  background-color: #4a494982;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
}

.blur-background {
  filter: blur(2px);
  transition: filter 0.3s ease-in-out;
}

.rating_popup {
  z-index: 5;
  display: none;
  position: absolute;
  top: 15%;
  left: 23%;
  width: 70vw;
  height: 60vh;
  flex-direction: column;
  background-color: white;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
}

.imageFeedBackInnerDiv {
  text-align: center;
  width: 50vw;
  flex-direction: column;
  height: 50vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.crossBtnDiv {
  display: "flex";
  width: "100%";
  justify-content: flex-end;
  align-items: "center";
  position: absolute;
  top: 10px;
  right: 10px;
}

.rating-popup {
  background-color: #4a494982;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
}

.emoji-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.emoji-rating img {
  width: 50px;
  height: 50px;
  transition: opacity 0.3s;
}

.emoji-rating img:hover {
  opacity: 1;
}

.imageFeedBackPopup {
  background-color: #4a494982;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
}

.imageFeedBackPopup div {
  text-align: center;
  width: 50vw;
  flex-direction: column;
  height: 50vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
}

.feedbackbtn {
  color: #fff;
  background-color: #006cca;
  border: 2px solid rgba(0, 0, 0, 0);
  height: 46px;
  font-weight: 600;
  width: 220px;
}

.scroll-container {
  height: 400px;
  overflow-y: scroll;
}

/* Custom scrollbar styles for WebKit browsers (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
  width: 16px;
  /* Make the scrollbar thicker */
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
  border: 4px solid #f1f1f1;
  /* Padding around scrollbar thumb */
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Custom scrollbar styles for Firefox */
.scroll-container {
  scrollbar-width: thick;
  /* Makes scrollbar thicker */
  scrollbar-color: #888 #f1f1f1;
  /* Scrollbar thumb and track color */
}

/* Custom scrollbar styles for Internet Explorer and Edge */
@supports (-ms-overflow-style: none) {
  .scroll-container {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* Hide default scrollbar */
  }

  .scroll-container::-ms-scrollbar {
    width: 16px;
    /* Make the scrollbar thicker */
  }

  .scroll-container::-ms-scrollbar-track {
    background: #f1f1f1;
  }

  .scroll-container::-ms-scrollbar-thumb {
    background-color: #888;
    border-radius: 8px;
    border: 4px solid #f1f1f1;
    /* Padding around scrollbar thumb */
  }

  .scroll-container::-ms-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.upArrow {
  display: none;
  position: absolute;
  bottom: 80px;
  height: 50px;
  width: 100%;
  justify-content: center;
}

.downArrow {
  justify-content: center;
  display: none;
  position: absolute;
  top: 80px;
  width: 100%;
  height: 48px;
}

.downArrow img,
.upArrow img {
  margin-left: 10px;
  width: 30px;
  background-color: transparent;
}

.downArrow p,
.upArrow p {
  border-radius: 16px;
  height: 15px;
  background-color: hsla(0, 0%, 100%, 0.45);
  padding: 5px;
  font-weight: 600;
}

.uploadMainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100dvh;
  width: 100dvw;
  position: relative;
}

.ImageDiv {
  overflow-y: scroll;
  flex-wrap: wrap;
  width: 100vw;
  height: 75dvh;
}

.buttonDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100dvw;
  height: 20dvh;
}

.uploadPopup {
  top: 0px;
  left: 0px;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100dvh;
  width: 100dvw;
}

.popupDiv {
  width: 100dvw;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  bottom: 10px;
}

.errormssg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 5dvh;
}

.waringpopuup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgba(128, 128, 128, 0.241);
}

.waringpopuupdiv {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  height: 50dvh;
  background-color: white;
}

.buttonDiv div {
  width: 180px;
}

/* Stencils */
.car-stencil {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  pointer-events: none;
}

.landscape_rectangle_block {
  display: none;
  position: absolute;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  opacity: 0.5;
}

.rectangle_block {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  pointer-events: none;
  z-index: 2;
  object-fit: cover;
}

.landscape_rectangle_block_damage {
  display: block;
  position: absolute;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  opacity: 0.5;
}

.rectangle_block_damage {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  pointer-events: none;
  z-index: 2;
  object-fit: cover;
}

#form-module-inner-div {
  display: flex;
  justify-content: space-between;
  margin: 30px 10px 10px 15px;
  flex-direction: column;
  width: 100%;
}

#incomplete-secoundText {
  display: block;
  font-weight: 500;
  margin: 0px;
}

#guidanceText {
  background: white;
  width: auto;
  height: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  border-radius: 20px;
  padding: 0% 2% 0% 2%;
}

#guidanceParaText {
  font-size: 130%;
  font-weight: 500;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 680px) and (orientation: landscape) {

  #guidanceParaText {
    font-size: 110%;
  }
}

#rotateBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

#rotateBtnContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 3%;
}

/*  */